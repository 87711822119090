import React from 'react';
import { Grid, Paper, Typography, Box, Card, CardContent, Avatar } from '@mui/material';
import { red, green } from '@mui/material/colors';
import Chart from 'react-apexcharts';
import MainContent from '../components/MainContent';

const stats = [
  { title: 'Faturamento', value: 'R$000k', change: '+37.8%', icon: <Avatar sx={{ bgcolor: green[500] }}>R$</Avatar> },
  { title: 'Ganhos', value: 'R$000k', change: '+37.8%', icon: <Avatar sx={{ bgcolor: green[500] }}>R$</Avatar> },
  { title: 'Perdas', value: 'R$000k', change: '-37.8%', icon: <Avatar sx={{ bgcolor: red[500] }}>R$</Avatar> },
  { title: 'Ganhos Totais', value: 'R$000k', change: '+37.8%', icon: <Avatar sx={{ bgcolor: green[500] }}>R$</Avatar> },
];

const chartOptions = {
  chart: {
    type: 'area' as const,
  },
  series: [
    {
      name: 'Lorem Ipsum',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ],
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
  },
};

const pieOptions = {
  chart: {
    type: 'donut' as const,
  },
  series: [65, 35],
  labels: ['Lorem Ipsum', 'Lorem Ipsum'],
};

const Home = () => {
  return (
      <Box>
        <Typography variant="h5">Olá, Lorem Ipsum</Typography>
        <Grid container spacing={2} sx={{ my: 2 }}>
          {stats.map((stat) => (
            <Grid item xs={12} sm={6} md={3} key={stat.title}>
              <Card>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    {stat.icon}
                    <Box ml={2}>
                      <Typography variant="h6">{stat.title}</Typography>
                      <Typography variant="body2">{stat.value}</Typography>
                      <Typography variant="caption" color={stat.change.startsWith('+') ? 'success.main' : 'error.main'}>
                        {stat.change}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Lorem Ipsum</Typography>
              <Chart options={chartOptions} series={chartOptions.series} type="area" height={350} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Lorem Ipsum</Typography>
              <Chart options={pieOptions} series={pieOptions.series} type="donut" height={350} />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={12} md={9}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Usuários</Typography>
              <Box sx={{ overflowX: 'auto' }}>
                {/* Adicione sua tabela aqui */}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">Tarefas de Hoje</Typography>
              <Box>
                {/* Adicione suas tarefas aqui */}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
  );
};

export default Home;
