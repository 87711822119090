import React from 'react';
import { TextField, MenuItem, TextFieldProps, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const StyledSelect = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root, & .MuiOutlinedInput-root, & .MuiInput-underline': {
    background: 'linear-gradient(330deg, #202020, #202020, #202020)',
    border: '0.3px solid #65626240',
    boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.5)',
    color: '#ccc',
    paddingLeft: '0px',
    '&::placeholder': {
      color: '#ccc',
    },
    '& .MuiInputAdornment-root svg': {
      color: '#000',
    },
    '&:hover': {
      background: 'linear-gradient(330deg, #202020, #0a0a0a40, #0a0a0a00)',
    },
    '&.Mui-focused': {
      background: 'linear-gradient(92deg, #141414, #1e1e1e, #212121, #1a1a1a, #141414, #0b0b0b, #090909)',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '& .MuiInputAdornment-root svg': {
        color: theme.palette.primary.main,
      },
    },
    '&.Mui-focused::placeholder': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: '#ccc',
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
  '& .MuiInputAdornment-root': {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '8px',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '56px',
  height: '56px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '6px 0px 1px 6px;',
  marginRight: '-4px',
  zIndex: 2,
}));

interface CustomSelectProps {
  icon?: React.ReactNode;
  options: Array<{ value: string; label: string; match_hometeam_badge: string; match_awayteam_badge: string }>;
  textFieldProps: TextFieldProps;
}

const CustomSelectTeamsLogo: React.FC<CustomSelectProps> = ({ icon, options, textFieldProps }) => {
  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      {icon && (
        <IconWrapper>
          {icon}
        </IconWrapper>
      )}
      <StyledSelect
        select
        {...textFieldProps}
        InputProps={{
          ...textFieldProps.InputProps,
          startAdornment: null,
        }}
        SelectProps={{
          renderValue: (selected) => {
            const selectedOption = options.find(option => option.value === selected);
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={selectedOption?.match_hometeam_badge} alt={selectedOption?.label} style={{ width: 20, height: 20, marginRight: 8 }} />
                {selectedOption?.label}
                <img src={selectedOption?.match_awayteam_badge} alt={selectedOption?.label} style={{ width: 20, height: 20, marginLeft: 8 }} />
              </div>
            );
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={option.match_hometeam_badge} alt={option.label} style={{ width: 20, height: 20, marginRight: 8 }} />
              {option.label}
              <img src={option.match_awayteam_badge} alt={option.label} style={{ width: 20, height: 20, marginLeft: 8 }} />
            </div>
          </MenuItem>
        ))}
      </StyledSelect>
    </div>
  );
};

export default CustomSelectTeamsLogo;
