import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Drawer,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useTheme,
} from '@mui/material';
import {
  Home as HomeIcon,
  BarChart as AnalyticsIcon,
  AccountCircle as ProfileIcon,
  People as UsersIcon,
  Settings as SettingsIcon,
  Menu as MenuIcon,
  ExitToApp as LogoutIcon,
} from '@mui/icons-material';
import logo from '../assets/Vector.png';

const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/' },
    { text: 'Analytics', icon: <AnalyticsIcon />, path: '/tickets' },
    { text: 'Profile', icon: <ProfileIcon />, path: '/profile' },
    { text: 'Users', icon: <UsersIcon />, path: '/users' },
    { text: 'Settings', icon: <SettingsIcon />, path: '/settings' },
    { text: 'Logout', icon: <LogoutIcon />, path: '/logout' },
  ];

  return (
    <Drawer
      variant="permanent"
      open={!collapsed}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          width: collapsed ? 80 : 240,
          transition: 'width 0.3s',
        },
      }}
    >
      <Toolbar>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          {!collapsed && <img src={logo} alt="Logo" style={{ height: 40 }} />}
          <IconButton onClick={toggleCollapse} sx={{ color: theme.palette.text.primary }}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            selected={location.pathname === item.path}
            sx={{
              backgroundColor: location.pathname === item.path ? theme.palette.primary.main : 'inherit',
              color: location.pathname === item.path ? theme.palette.primary.contrastText : 'inherit',
              '&:hover': {
                backgroundColor: location.pathname === item.path ? theme.palette.primary.dark : theme.palette.action.hover,
              },
            }}
          >
            <ListItemIcon sx={{ color: location.pathname === item.path ? theme.palette.primary.contrastText : theme.palette.text.primary }}>
              {item.icon}
            </ListItemIcon>
            {!collapsed && <ListItemText primary={item.text} />}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
