import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Admin, Resource } from 'react-admin';
import dataProvider from './dataProvider';
import Home from './pages/Home';
import Analytics from './pages/Analytics';
import Profile from './pages/Profile';
import UserList from './pages/Users';
import CustomLayout from './layouts/CustomLayout';
import theme from './styles/themes';
import LoginPage from './pages/LoginPage';
import Sidebar from './components/Sidebar';
import MainContent from './components/MainContent';
import TicketPage from './pages/TicketsPage/TicketPage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import EditTicketPage from './pages/TicketsPage/Edit/EditTicketPage';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <MainContent>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/*" element={<ProtectedRoute><AdminRoutes /></ProtectedRoute>} />
            </Routes>
          </MainContent>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

const AdminRoutes = () => (
  <Admin
    dashboard={Home}
    dataProvider={dataProvider}
    theme={theme}
    layout={CustomLayout}
  >
    <Sidebar />
    <Resource name="analytics" list={Analytics} />
    <Resource name="profile" list={Profile} />
    <Resource name="users" list={UserList} />
    <Resource name="tickets" list={TicketPage} />
    <Resource name="tickets/:id" list={<EditTicketPage />} />
  </Admin>
);

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default App;
