import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import useMatches from "../../../hooks/useMatches";
import api from "../../../api/api";
import CustomSelect from "../../../components/CustomSelect";
import CustomInput from "../../../components/CustomInput";
import CustomSelectTeamsLogo from "../../../components/CustomSelectTeamsLogo";
import CustomButton from "../../../components/CustomButton";
import CustomCheckbox from "../../../components/CustomCheckbox";
import { useParams } from "react-router-dom";
import { match } from "assert";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormGroup = styled.div`
  max-width: 100%;
  display: flex;
  gap: 16px;
  flex: 1;
  flex-wrap: wrap;
  & > * {
    flex: 1;
    min-width: 150px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  flex-basis: 100%;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const EditTicketForm = ({ ticket, onSave, onCancel, countries }) => {
  const { id } = useParams();
  const [formState, setFormState] = useState(
    ticket || { country: "", expiration_date: "", game: "", new_ticket: false }
  );
  const [country, setCountry] = useState(formState.country);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { matches } = useMatches(country, from, to);

  useEffect(() => {
    setFormState(
      ticket || {
        country: "",
        expiration_date: "",
        game: "",
        new_ticket: false,
      }
    );
  }, [ticket]);

  useEffect(() => {
    setCountry(formState.country);
  }, [formState.country]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "from") {
      setFrom(value);
    } else if (name === "to") {
      setTo(value);
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setFormState({ ...formState, country: selectedCountry });
  };

  const handleGameChange = (event) => {
    const selectedGame = event.target.value;
    setFormState({ ...formState, game: selectedGame });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedMatch = matches.find(
      (match) => match.match_id === formState.game
    );

    if (selectedMatch) {
      const newMatch = {
        home_team_name: selectedMatch.match_hometeam_name,
        home_team_logo: selectedMatch.match_hometeam_badge,
        home_team_score: 0,
        away_team_name: selectedMatch.match_awayteam_name,
        away_team_logo: selectedMatch.match_awayteam_badge,
        away_team_score: 0,
        match_league: selectedMatch.league_name,
        match_id_api: selectedMatch.match_id,
        match_date: selectedMatch.match_date,
        ticket_id: id,
        match_time: selectedMatch.match_time,
      };

      setIsLoading(true);

      try {
        const response = await api.post("/matches", newMatch);
        onSave(response.data);
      } catch (error) {
        console.error("Error submitting the form: ", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      console.error("Selected match not found");
    }
  };

  const countryOptions = countries.map((country) => ({
    value: country,
    label: country,
  }));

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <CustomSelect
          icon={<LocationOnIcon style={{ color: "#000" }} />}
          options={countryOptions}
          textFieldProps={{
            label: "País",
            name: "country",
            value: formState.country,
            onChange: handleCountryChange,
            fullWidth: true,
          }}
        />
        <CustomInput
          icon={<DateRangeIcon style={{ color: "#000" }} />}
          textFieldProps={{
            label: "Data Início",
            name: "from",
            type: "date",
            value: from,
            onChange: handleDateChange,
            fullWidth: true,
            InputLabelProps: {
              shrink: true,
            },
            placeholder: "dd/mm/aaaa",
          }}
        />
        <CustomInput
          icon={<DateRangeIcon style={{ color: "#000" }} />}
          textFieldProps={{
            label: "Data Fim",
            name: "to",
            type: "date",
            value: to,
            onChange: handleDateChange,
            fullWidth: true,
            InputLabelProps: {
              shrink: true,
            },
            placeholder: "dd/mm/aaaa",
          }}
        />
        <CustomSelectTeamsLogo
          icon={<SportsSoccerIcon style={{ color: "#000" }} />}
          options={matches.map((match) => ({
            value: match.match_id,
            label: `${match.match_hometeam_name} VS ${match.match_awayteam_name}`,
            match_hometeam_badge: match.match_hometeam_badge,
            match_awayteam_badge: match.match_awayteam_badge,
          }))}
          textFieldProps={{
            label: "Jogo",
            name: "game",
            value: formState.game,
            onChange: handleGameChange,
            fullWidth: true,
          }}
        />
        <CustomCheckbox
          label="Novo Bilhete"
          name="new_ticket"
          checked={formState.new_ticket}
          onChange={handleChange}
        />
      </FormGroup>
      <ButtonGroup>
        <CustomButton color="primary" type="submit" disabled={isLoading}>
          {isLoading ? "Salvando..." : "Salvar"}
        </CustomButton>
        <CustomButton color="secondary" onClick={onCancel} disabled={isLoading}>
          Cancelar
        </CustomButton>
      </ButtonGroup>
    </Form>
  );
};

export default EditTicketForm;
