import React, { ReactNode } from 'react';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

interface CustomLayoutProps {
  children: ReactNode;
}

const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
  return (
    <Box display="flex">
      <CssBaseline />
      <Sidebar />
      <Box flexGrow={1} bgcolor="background.default" p={3}>
        <Header />
        {children}
      </Box>
    </Box>
  );
};

export default CustomLayout;
