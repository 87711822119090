import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useTickets from '../../hooks/useTickets';
import { useNavigate } from 'react-router-dom';
import CreateTicketForm from './CreateTicketForm';
import { Delete, EditLocation, EditOutlined } from '@material-ui/icons';
import { Edit, EditButton } from 'react-admin';
import api from '../../api/api';

const PageContainer = styled.div`
  padding: 32px;
  color: #fff;
`;

const Header = styled.h1`
  color: #fff;
`;

const Card = styled.div`
  background-color: #1c1c1c;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const CardTitle = styled.h2`
  margin: 0;
  color: #bdff00;
`;

const CardActions = styled.div`
  display: flex;
  gap: 8px;
`;

const CardContent = styled.div`
  color: #ccc;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #bdff00;
  }
`;

const TicketPage = () => {
  const { fetchedTickets, loading, error, fetchTickets } = useTickets();
  const [tickets, setTickets] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (fetchedTickets) {
      setTickets(fetchedTickets);
    }
  }, [fetchedTickets]);

  const handleAddTicket = async (ticket) => {
    await fetchTickets();  // Fetch updated list of tickets
  };

  const handleDeleteTicket = async (id) => {
    try {
      await api.delete(`/tickets/${id}`);
      await fetchTickets();  // Fetch updated list of tickets
    } catch (error) {
      console.error("Error deleting ticket: ", error);
    }
  };

  const handleEditClick = (ticket) => {
    navigate(`/tickets/${ticket.ticket_id}`);
  };

  return (
    <PageContainer>
      <Header>Gerenciamento de Bilhetes</Header>
      <CreateTicketForm onSave={handleAddTicket} />
      {tickets.map((ticket) => (
        <Card key={ticket.ticket_id}>
          <CardHeader>
            <CardTitle>Bilhete #{ticket.ticket_id}</CardTitle>
            <CardActions>
              <IconButton onClick={() => handleEditClick(ticket)}>
                <EditOutlined color='secondary' />
              </IconButton>
              <IconButton onClick={() => handleDeleteTicket(ticket.ticket_id)}>
                <Delete color='error'/>
              </IconButton>
            </CardActions>
          </CardHeader>
          <CardContent>
            <p>Data de Expiração: {new Date(ticket.ticket_expiration_date).toLocaleDateString()}</p>
            <p>Horas: {new Date(ticket.ticket_expiration_date).toLocaleTimeString()}</p>
          </CardContent>
        </Card>
      ))}
    </PageContainer>
  );
};

export default TicketPage;
