import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Avatar, Checkbox, FormControlLabel, Link } from '@mui/material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../contexts/AuthContext';
import logoHome from '../assets/logo-home.png'

const Background = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  flex-direction: column;
`;

const LoginContainer = styled(Paper)`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1c1c1c;
  border: 2px solid #bdff00;
  border-radius: 10px;
`;

const StyledTextField = styled(TextField)`
  margin: 1rem 0 !important;

  .MuiInputBase-root {
    color: #fff;
  }

  .MuiInputLabel-root {
    color: #bdff00;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #bdff00;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #bdff00;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #bdff00;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 1.5rem !important;
  background-color: #bdff00 !important;
  color: #000 !important;
  &:hover {
    background-color: #a2e600 !important;
  }
`;

const ErrorMessage = styled(Box)`
  background-color: #8b0000;
  color: #fff;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
`;

const Footer = styled(Box)`
  margin-top: 2rem;
  color: #888;
  text-align: center;
  font-size: 0.875rem;

  a {
    color: #bdff00;
    text-decoration: none;
    margin-left: 0.5rem;
  }
`;

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleLogin = async () => {
    const success = await login(email, password);
    if (success) {
      navigate('/');
    } else {
      setError('Email ou senha inválido(s). Tente novamente');
    }
  };

  return (
    <Background>
       <img src={logoHome} alt="Logo" style={{ width: '200px', marginBottom: '1rem' }} />
      <LoginContainer elevation={10}>
       
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Typography component="h1" variant="h5" style={{ color: '#fff' }}>
          Bem-vindo!
        </Typography>
        <Typography variant="body2" style={{ color: '#888', marginBottom: '1rem' }}>
          Para iniciar, faça login na sua conta.
        </Typography>
        <StyledTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Insira seu e-mail"
        />
        <StyledTextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Senha"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Insira sua senha"
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Manter logado?"
          style={{ color: '#fff' }}
        />
        <StyledButton
          type="submit"
          fullWidth
          variant="contained"
          onClick={handleLogin}
        >
          Entrar
        </StyledButton>
        <Link href="#" variant="body2" style={{ color: '#bdff00', marginTop: '1rem' }}>
          Esqueceu a sua senha?
        </Link>
        <Footer>
          <Typography variant="body2">
            Ainda não tem uma conta?
            <Link href="#"> Registre-se</Link>
          </Typography>
          <Typography variant="body2" style={{ marginTop: '1rem' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam elementum, enim eu consequat sodales, purus sem imperdiet elit, vitae elementum risus mauris nec ante.
          </Typography>
        </Footer>
      </LoginContainer>
    </Background>
  );
};

export default LoginPage;
