import React, { useState } from 'react';
import styled from 'styled-components';
import CustomInput from '../../components/CustomInput';
import CustomButton from '../../components/CustomButton';
import api from '../../api/api';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormGroup = styled.div`
  max-width: 100%;
  display: flex;
  gap: 16px;
  flex: 1;
  flex-wrap: wrap;
  & > * {
    flex: 1;
    min-width: 150px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  flex-basis: 100%;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const CreateTicketForm = ({ onSave }) => {
  const [expirationDate, setExpirationDate] = useState('');

  const handleChange = (event) => {
    setExpirationDate(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post('/tickets', {
        ticket_expiration_date: expirationDate,
      });
      onSave(response.data);
    } catch (error) {
      console.error("Error creating ticket: ", error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <CustomInput
          textFieldProps={{
            label: "Data de Expiração",
            name: "ticket_expiration_date",
            type: "datetime-local",
            value: expirationDate,
            onChange: handleChange,
            fullWidth: true,
            InputLabelProps: {
              shrink: true,
            },
          }}
        />
      </FormGroup>
      <ButtonGroup>
        <CustomButton color="primary" type="submit">
          Criar Bilhete
        </CustomButton>
      </ButtonGroup>
    </Form>
  );
};

export default CreateTicketForm;
