import React from 'react';
import { TextField, TextFieldProps, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root, & .MuiOutlinedInput-root, & .MuiInput-underline': {
    background: 'linear-gradient(330deg, #202020, #202020, #202020)',
    border: '0.3px solid #65626240',
    boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.5)',
    color: '#ccc',
    paddingLeft: '0', // Adicionado para espaço do ícone
    '&::placeholder': {
      color: '#ccc',
    },
    '& .MuiInputAdornment-root svg': {
      color: '#000', // Ícone preto
    },
    '&:hover': {
      background: 'linear-gradient(330deg, #202020, #0a0a0a40, #0a0a0a00)',
    },
    '&.Mui-focused': {
      background: 'linear-gradient(92deg, #141414, #1e1e1e, #212121, #1a1a1a, #141414, #0b0b0b, #090909)',
      borderColor: '#BDFF00',
      color: '#BDFF00',
      '& .MuiInputAdornment-root svg': {
        color: '#000', // Ícone preto quando ativo
      },
    },
    '&.Mui-focused::placeholder': {
      color: '#BDFF00',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#ccc',
    '&.Mui-focused': {
      color: '#BDFF00',
    },
  },
  '& .MuiInputAdornment-root': {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: '8px',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '56px',
  height: '56px',
  backgroundColor: '#BDFF00',
  borderRadius: '6px 0 0 6px',
  marginRight: '-4px',
  zIndex: 2,
}));

interface CustomInputProps {
  icon?: React.ReactNode;
  textFieldProps: TextFieldProps;
}

const CustomInput: React.FC<CustomInputProps> = ({ icon, textFieldProps }) => {
  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      {icon && (
        <IconWrapper>
          {icon}
        </IconWrapper>
      )}
      <StyledTextField
        {...textFieldProps}
        InputProps={{
          ...textFieldProps.InputProps,
          startAdornment: null,
        }}
      />
    </div>
  );
};

export default CustomInput;
