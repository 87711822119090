import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useTickets from "../../../hooks/useTickets";
import MatchList from "../../../components/MatchList";
import EditTicketForm from "./EditTicketForm";
import { countries } from "../../../utils/countries";
import CustomButton from "../../../components/CustomButton";

const PageContainer = styled.div`
  padding: 32px;
  color: #fff;
`;

const Header = styled.h1`
  color: #fff;
`;

const EditTicketPage = () => {
  const { id } = useParams();
  const { fetchTicketById, loading, error, updateTicketState } = useTickets();
  const [ticket, setTicket] = useState(null);

  useEffect(() => {
    const loadTicket = async () => {
      const fetchedTicket = await fetchTicketById(id);
      setTicket(fetchedTicket);
    };

    loadTicket();
  }, [id]);

  const handleSave = async (updatedTicket) => {
    const fetchedTicket = await fetchTicketById(id); // Fetch updated ticket with new matches
    setTicket(fetchedTicket);
  };

  const updateTicket = async () => {
    await updateTicketState(ticket.ticket_id);
    const fetchedTicket = await fetchTicketById(id);
    setTicket(fetchedTicket);
  };

  const handleCancel = () => {
    // lógica para cancelar a edição
  };

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro ao carregar ticket.</p>;
  if (!ticket) return <p>Bilhete não encontrado.</p>;

  return (
    <PageContainer>
      <Header>Editar Bilhete #{ticket.ticket_id}</Header>
      <CustomButton onClick={updateTicket}> Atualizar Ticket </CustomButton>
      <EditTicketForm
        ticket={ticket}
        onSave={handleSave}
        onCancel={handleCancel}
        countries={countries}
      />
      <MatchList matches={ticket.match_infos} selectedMatch={1} />{" "}
      {/* Exemplo de partida selecionada */}
    </PageContainer>
  );
};

export default EditTicketPage;
