import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#BDFF00',
      contrastText: '#000000',
    },
    secondary: {
      main: '#ff0000',
      contrastText: '#ffffff',
    },
    background: {
      default: '#000000',
      paper: '#1c1c1c',
    },
    text: {
      primary: '#ffffff',
      secondary: '#cccccc',
    },
    grey: {
      500: '#A2A49D',
    },
  },
  typography: {
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          padding: '12px 24px',
        },
        containedPrimary: {
          backgroundColor: '#BDFF00',
          color: '#000000',
          '&:hover': {
            backgroundColor: '#A2E600',
          },
        },
        containedSecondary: {
          backgroundColor: '#ff0000',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#e60000',
          },
        },
        contained: {
          backgroundColor: '#A2A49D',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#8F9187',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root, & .MuiOutlinedInput-root, & .MuiInput-underline': {
            background: 'linear-gradient(330deg, #202020, #202020, #202020)',
            border: '0.3px solid #65626240',
            boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.5)',
            color: '#ccc',
            '&::placeholder': {
              color: '#ccc',
            },
            '& .MuiInputAdornment-root svg': {
              color: '#ccc',
            },
            '&:hover': {
              background: 'linear-gradient(330deg, #202020, #0a0a0a40, #0a0a0a00)',
            },
            '&.Mui-focused': {
              background: 'linear-gradient(92deg, #141414, #1e1e1e, #212121, #1a1a1a, #141414, #0b0b0b, #090909)',
              borderColor: '#BDFF00',
              color: '#BDFF00',
              '& .MuiInputAdornment-root svg': {
                color: '#BDFF00',
              },
            },
            '&.Mui-focused::placeholder': {
              color: '#BDFF00',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#ccc',
            '&.Mui-focused': {
              color: '#BDFF00',
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#BDFF00',
        },
      },
    },
  },
});

export default theme;
