import { useState, useEffect } from "react";
import api from "../api/api";

const useTickets = () => {
  const [fetchedTickets, setFetchedTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const response = await api.get("/tickets");
      setFetchedTickets(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchTicketById = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`/tickets/${id}`);
      return response.data;
    } catch (err) {
      setError(err);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateTicketState = async (id) => {
    setLoading(true);
    try {
      const response = await api.patch(`/tickets/update-state/${id}`);
      return response.data;
    } catch (err) {
      setError(err);
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  return {
    fetchedTickets,
    loading,
    error,
    fetchTicketById,
    fetchTickets,
    updateTicketState,
  };
};

export default useTickets;
