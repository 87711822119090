import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import ApexCharts from 'react-apexcharts';

const Analytics = () => {
  const chartOptions = {
    chart: {
      type: 'line',
      background: '#1e1e1e', // Ajuste conforme necessário
    },
    series: [
      {
        name: 'Series 1',
        data: [10, 20, 30, 40, 50],
      },
    ],
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    },
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Analytics</Typography>
        <ApexCharts options={chartOptions as any} series={chartOptions.series} type="line" height="350" />
      </CardContent>
    </Card>
  );
};

export default Analytics;
