import { useState, useEffect } from 'react';
import api from '../api/api';

const useMatches = (country, from, to) => {
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!country || !from || !to) return;

    const fetchMatches = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/predictions?country=${country}&from=${from}&to=${to}`);
        const sortedMatches = response.data.sort((a, b) => a.match_hometeam_name.localeCompare(b.match_hometeam_name));
        setMatches(sortedMatches);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, [country, from, to]);

  return { matches, loading, error };
};

export default useMatches;
