import React from 'react';
import { Box, styled, useTheme } from '@mui/material';

const MainContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: '80px',
  transition: 'margin-left 0.3s',
  '@media (max-width: 600px)': {
    marginLeft: '80px',
  },
}));

const MainContent = ({ children }) => {
  return <MainContainer>{children}</MainContainer>;
};

export default MainContent;
