import React from 'react';
import { styled, useTheme } from '@mui/material';

const MatchListContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(600px, 1fr))',
  gap: theme.spacing(2.5),
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2.5),
}));

const MatchItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.875),
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[1],
  justifyContent: 'space-between',
  border: `2px solid transparent`,
  overflow: 'hidden',
  '&.selected': {
   // border: `2px solid ${theme.palette.primary.main}`,
  },
}));

const MatchNumber = styled('div')(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.primary.main,
  marginRight: theme.spacing(1.875),
}));

const MatchDetails = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const MatchTitle = styled('div')(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
}));

const MatchDate = styled('div')(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.text.secondary,
}));

const TeamIcons = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'auto',
}));

const TeamIcon = styled('img')(() => ({
  width: '50px',
  margin: '0 10px',
}));

const TeamName = styled('div')(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  margin: '0 10px',
}));

const TeamScore = styled('div')(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(0.625),
}));

const MatchVs = styled('div')(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  margin: '0 10px',
}));

const MatchList = ({ matches, selectedMatch }) => {
  return (
    <MatchListContainer>
      {matches?.map((match, index) => (
        <MatchItem key={match.match_id} className={selectedMatch === index ? 'selected' : ''}>
          <MatchNumber>{index + 1}</MatchNumber>
          <MatchDetails>
            <MatchTitle>{match.match_league}</MatchTitle>
            <MatchDate>{match.match_date || 'Data não disponível'}</MatchDate>
          </MatchDetails>
          <TeamIcons>
            <TeamIcon src={match.home_team_logo} alt={match.home_team_name} />
            <TeamName>{match.home_team_name}</TeamName>
            <TeamScore>{match.home_team_score}</TeamScore>
            <MatchVs>VS</MatchVs>
            <TeamScore>{match.away_team_score}</TeamScore>
            <TeamName>{match.away_team_name}</TeamName>
            <TeamIcon src={match.away_team_logo} alt={match.away_team_name} />
          </TeamIcons>
        </MatchItem>
      ))}
    </MatchListContainer>
  );
};

export default MatchList;
