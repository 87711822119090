import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

const Profile = () => (
  <Card>
    <CardContent>
      <Typography variant="h5">Profile</Typography>
      <Typography>Name: Admin</Typography>
      <Typography>Email: admin@example.com</Typography>
    </CardContent>
  </Card>
);

export default Profile;
