import React from 'react';
import { Checkbox, FormControlLabel, CheckboxProps, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));

interface CustomCheckboxProps extends CheckboxProps {
  label: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ label, ...props }) => {
  return <FormControlLabel control={<StyledCheckbox {...props} />} label={label} />;
};

export default CustomCheckbox;
